import React from "react";
import { Layout } from "../../../../src/components";
import WishlistProducts from "../../components/wishlist/wishlist-products";

const WishList = () => {
  return (
    <Layout>
      <div className="layout">
        <h1>Wishlist</h1>
        <WishlistProducts />
      </div>
    </Layout>
  );
};

export default WishList;
