import React, { useContext } from "react";
import Link from "gatsby-link";
import AddToCartButton from "../../cart/add-to-cart-button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { isEmpty, remove } from "lodash";
import { AppContext } from "../../context/AppContext";

const productImagePlaceholder = "https://via.placeholder.com/434";

const WishlistProduct = (props) => {
  const { product } = props;

  const { setWishlist } = useContext(AppContext);

  if (isEmpty(product)) {
    return null;
  }

  const imgSrcUrl = product.image.sourceUrl ? product.image.sourceUrl : "";

  const handleRemoveFromWishList = () => {
    const existingWishlist = JSON.parse(localStorage.getItem("woo-wishlist"));
    const updatedItems = {
      productIds: remove(existingWishlist.productIds, (id) => {
        return product.productId !== id;
      }),
      products: remove(existingWishlist.products, (products) => {
        return product.productId !== products.productId;
      }),
    };
    localStorage.setItem("woo-wishlist", JSON.stringify(updatedItems));
    setWishlist(updatedItems);
  };

  return (
    // @TODO Need to handle Group products differently.
    !isEmpty(product) && "GroupProduct" !== product.type ? (
      <div className="col-lg-4 col-md-6 mb-5">
        <Link to={product.link} className="product-image">
          {!isEmpty(product.image) ? (
            <figure>
              <LazyLoadImage
                alt={product.image.altText ? product.image.altText : ""}
                src={imgSrcUrl} // use normal <img> attributes as props
                effect="blur"
              />
            </figure>
          ) : !isEmpty(productImagePlaceholder) ? (
            <figure>
              <LazyLoadImage alt="default" height="450" src={productImagePlaceholder} width="450" effect="blur" />
            </figure>
          ) : null}
        </Link>
        <div className="card-body text-center">
          <h3 className="card-header">{product.name ? product.name : ""}</h3>
          <h6 className="card-subtitle">{product.price}</h6>
          {product.nodeType === "SimpleProduct" ? (
            <AddToCartButton product={product} />
          ) : (
            <Link to={product.link} className="action">
              View
            </Link>
          )}
          <button onClick={handleRemoveFromWishList} className="action">
            Remove
          </button>
        </div>
      </div>
    ) : null
  );
};

export default WishlistProduct;
