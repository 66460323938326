import React, { useContext } from "react";
import Link from "gatsby-link";
import WishlistProduct from "../wishlist-product";
import { AppContext } from "../../context/AppContext";

const WishlistProducts = () => {
  const { wishlist } = useContext(AppContext);
  return (
    <div className="container my-5">
      <div className="container">
        <div className="product-container row">
          {wishlist?.products && wishlist.products.length > 0  ? (
            wishlist.products.map((product) => <WishlistProduct key={product.id} product={product} />)
          ) : (
            <div>
              <p>No items in wishlist</p>
              <Link to="/shop/" className="action">Shop</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WishlistProducts;
